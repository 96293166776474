import React from 'react'
import styled from 'styled-components'

import { useSplitTracking } from '../../hooks/useSplitTracking'
import linkTriangle from '../../images/linkTriangle.svg'
import { rspTheme } from '../../styles/rspTheme'
import Link from '../Link'

interface VerticalLinkProps {
  menuItem: any
  parentItem: string
  setCurrentSubNav: any
}

const VerticalLinkWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 12px;

  li {
    min-width: 120px;
  }
`

export const linkStyling = `
  text-decoration: 1px solid underline;
  text-decoration-color: transparent;
  color: ${rspTheme.palette.primary.main};
  position: relative;
  transition: 0.2s ease-in-out;

  &.highlighted {
    color: #b82537;
  }

  &::after,
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 5px;
    height: 9px;
    right: -12px;
    bottom: 4px;
    background-image: url(${linkTriangle});
    opacity: 0;
    transition: 0.2s ease-in-out;
  }

  &:focus,
  &:hover {
    text-decoration-color: inherit;
    outline: none;
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
    text-decoration: none;
  }

  &:focus::after,
  &:hover::after {
    opacity: 1;
  }
`

const Heading = styled.li`
  font-weight: 400;
  margin-bottom: 18px;

  a {
    ${linkStyling}
  }
`

const ListItem = styled.li`
  margin-bottom: 12px;

  a {
    font-weight: 300;
    ${linkStyling};
  }
`

const VerticalLink = (props: VerticalLinkProps) => {
  const { menuItem, parentItem, setCurrentSubNav } = props
  const track = useSplitTracking()

  const formatMenuItems = (words: string): string => {
    const lowerCased = words.toLowerCase().trim()
    return lowerCased.split(' ').join('_')
  }

  return (
    <VerticalLinkWrapper>
      <Heading>
        {menuItem.is_clickable === 'True' ? (
          <Link
            link={menuItem.link?.[0]}
            onFocus={() => {
              setCurrentSubNav(parentItem)
              track(`navbar_link_category__click`, undefined, {
                url: window.location.href,
                link_text: formatMenuItems(menuItem.link?.[0]?.text),
              })
            }}
            onMouseEnter={() => setCurrentSubNav(parentItem)}
          >
            {menuItem.link?.[0]?.text}
          </Link>
        ) : (
          menuItem.text
        )}
      </Heading>
      {menuItem.menu_items.map((menuItem: any) => (
        <React.Fragment key={menuItem.id}>
          {menuItem.text ? (
            <ListItem>
              <Link
                link={menuItem}
                onFocus={() => {
                  setCurrentSubNav(parentItem)
                  track(`navbar_link_item__click`, undefined, {
                    url: window.location.href,
                    link_text: menuItem.text,
                  })
                }}
                onMouseEnter={() => setCurrentSubNav(parentItem)}
              >
                {menuItem.text}
              </Link>
            </ListItem>
          ) : null}
        </React.Fragment>
      ))}
    </VerticalLinkWrapper>
  )
}

export default VerticalLink
